import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import { RFFORMINFO } from "../../../constants/applicationConstants";
import { DataTableHoc, Loader, ErrorFallback } from "smart-react";
import { GridColumnsList } from "../Components/Filter/FilterElements";
import DataColumns from "../Components/DataColumns/DataColumns";
import {
  ROW_LAYOUTS,
  PAGE_SIZE,
} from "../../../constants/applicationConstants";
import {
  deleteFilter,
  applyFilter,
  saveFilters,
} from "../../../Utils/Filters/filterSliderHandler";
const BaseProfileDataTable = React.lazy(
  () => import("../Components/DataTable/DataTable")
);
const RFFormInfoDataTable = DataTableHoc(BaseProfileDataTable);

/**
 * RFFormInfo main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The RFFormInfo component.
 */
const RFFormInfo = () => {
  const [userData, setUserData] = React.useState([]);
  const dataColumnsArray = DataColumns();
  // used to check API is ready to call
  const [isReady, setIsReady] = React.useState(false);
  const navigate = useNavigate();
  /**
   * navigate to edit screen
   * @param {*} event
   */
  const editScreen = (dataItem) => {
    navigate(`/rf-form-info`, {
      state: {
        edit: true,
        dataSet: dataItem,
      },
    });
  };
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className="container mx-auto">
        <React.Suspense fallback={<Loader />}>
          <RFFormInfoDataTable
            deleteFilter={deleteFilter}
            applyFilter={applyFilter}
            saveFilters={saveFilters}
            moduleName={RFFORMINFO}
            dataColumns={dataColumnsArray}
            gridColumnsList={() => GridColumnsList({ editScreen })}
            dataTableName={RFFORMINFO}
            setIsReady={setIsReady}
            isReady={isReady}
            rowLayoutConfigName={`${RFFORMINFO}.DataGrid.RowLayout`}
            pageLengthConfigName={`${RFFORMINFO}.DataGrid.ItemsPerPage`}
            dataSet={userData}
            setDataSet={setUserData}
            initialSort={{
              field: "id",
              dir: "desc",
            }}
            isCardonMobileView={false}
            total={process.env.DefaultLimit}
            defaultRowLayout={process.env.RowLayout}
            rowLayouts={ROW_LAYOUTS}
            pageSizes={PAGE_SIZE}
          />
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};
export default RFFormInfo;
